import { EnvConfig } from './config';

window.xsollaPayCardsRemoteUrl = EnvConfig.XSOLLA_PAY_CARDS_REMOTE_URL;
window.virtualCurrencyRemoteUrl = EnvConfig.VIRTUAL_CURRENCY_REMOTE_URL;
window.backpackRemoteUrl = EnvConfig.BACKPACK_REMOTE_URL;
window.parentalControlRemoteUrl = EnvConfig.PARENTAL_CONTROL_REMOTE_URL;
window.moduleTemplateRemoteUrl = EnvConfig.MODULE_TEMPLATE_REMOTE_URL;
window.xsollaMoneyRemoteUrl = EnvConfig.XSOLLA_MONEY_REMOTE_URL;

import('./bootstrap');
